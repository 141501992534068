
import {
    computed, defineAsyncComponent, defineComponent, ref,
    watch
} from 'vue';
import { TabsData } from '@/views/pm/device/types/all.type';
import router, {
    installerBaseRouter, installerSubRouter
} from '@/router';
import { isCNServer } from '@/util/location';

export default defineComponent({
    props: {
        account: {
            type: String
        },
        familySip: {
            type: String
        },
        // 是否选择高级功能 0关 1开
        premiumPlan: {
            type: String,
            default: '0'
        },
        // 设备类型 akuvox设备 和 第三方设备
        type: {
            type: String,
            default: 'intercom'
        },
        // 第三方设备三级菜单标识
        deviceType: {
            type: String,
            default: ''
        },
        personalAccountUUID: {
            type: String,
            default: ''
        },
        owner: {
            type: String,
            default: ''
        }
    },
    setup(props) {
        // 三级菜单 begin
        const baseTabs: TabsData = {
            control: {
                label: WordList.ControlPanel,
                name: 'control',
                component: defineAsyncComponent(() => import('@/views/installer/user/components/control-devices.vue'))
            },
            camera: {
                label: WordList.Camera,
                name: 'camera',
                component: defineAsyncComponent(() => import('@/views/installer/user/components/camera-devices.vue'))
            }
        };
        // 判断中控和三方是否展示
        const isShowControl = isCNServer();
        const isShowCamera = props.premiumPlan === '1';
        const tabsData = ref(baseTabs);
        if (!isShowControl && isShowCamera) {
            tabsData.value = { camera: baseTabs.camera };
        } else if (isShowControl && !isShowCamera) {
            tabsData.value = { control: baseTabs.control };
        } else {
            tabsData.value = baseTabs;
        }

        // 控制第三方设备展示标签页数据
        const tabsActiveName = ref(props.deviceType === '' ? Object.keys(tabsData.value)[0] : props.deviceType);

        const changeTab = (name: any) => {
            router.push(`/${installerBaseRouter}/${installerSubRouter.userdevice}${
                props.account ? `?account=${props.account}&familySip=${
                    props.familySip}&premiumPlan=${props.premiumPlan}&type=${props.type}&deviceType=${
                    name.paneName}&personalAccountUUID=${props.personalAccountUUID}&owner=${
                    props.owner}` : ''}`);
        };
        // 三级菜单end

        // 二级菜单部分 begin
        const isShowThirdPartyDeviceUI = computed(() => props.premiumPlan === '1' || isCNServer());

        // 控制akuvox设备和第三方设备展示的数据
        const tabsButtonName = computed(() => props.type);
        const tabsButtonData: TabsData = {
            intercom: {
                label: WordList.IntercomDevices,
                name: 'intercom',
                component: defineAsyncComponent(() => import('@/views/installer/user/components/intercom-device.vue'))
            },
            thirdPartyDevices: {
                label: WordList.ThirdPartyDevices,
                name: 'thirdPartyDevices',
                component: ''
            }
        };
        const changeTabsButton = (key: string) => {
            router.push(`/${installerBaseRouter}/${installerSubRouter.userdevice}?account=${
                props.account}&familySip=${props.familySip}&premiumPlan=${
                props.premiumPlan}&type=${key}&personalAccountUUID=${props.personalAccountUUID}&owner=${
                props.owner}&deviceType=${tabsActiveName.value}`);
        };
        // 二级菜单部分 end

        return {
            isShowThirdPartyDeviceUI,
            tabsButtonName,
            tabsButtonData,
            changeTabsButton,
            tabsActiveName,
            tabsData,
            changeTab
        };
    }
});
